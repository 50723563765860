import React from 'react'
import {Button, Modal} from 'react-bootstrap'
import {Link} from 'gatsby'
import 'components/css/modal.css'
import {Span} from 'components'

function Sales() {
  return (
    <div className="modal-view">
      <Link to="/careers" state={{ modal: false }}>
        <Button className="close" id="close-button">
         <Span aria-hidden="true">x</Span>
        </Button>
      </Link>

      <h2>TECH LEAD</h2><hr/>
    <br/>
      <Modal.Title><h4>MINIMUM QUALIFICATIONS</h4></Modal.Title>
      <Modal.Body>
        <ul>
          <li>Excellent technical ability, leadership and decision-making, with strong communication skills.</li>
          <li>Adaptable to new technology. Knowledge of Ruby/Rails.</li>
          <li>Deep background on transactional databases and ACID-compliance.</li>
          <li>Experience with highly concurrent systems.</li>
          <li>Strong hands-on experience with AWS.</li>
          <li>Good familiarity with DevOps practices and applications.</li>
        </ul>
      </Modal.Body>
      <a href="mailto: careers@digipay.ph" className="resume"><Button className="resume-button">Send Resume</Button></a>
    </div>
  )
}

export default Sales
